.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid @primary;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @white;
}
.ant-menu-item-selected {
  color: @primary;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: @primary;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: @white;
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
}

.home-page {
  width: 100%;
  .navbar {
    width: 100%;
    .header-main {
      width: calc(100% - 250px);
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      li {
        margin-right: 40px;
        a {
          font-size: 16px;
          color: #637381;
          &.active {
            color: @primary;
          }
          &:hover {
            text-decoration: none;
            color: @primary;
          }
        }
      }
      h3 {
        margin-bottom: 0;
      }
    }
    .top-navbar {
      width: 100%;
      display: flex;
      align-items: center;
      .rsp-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      img {
        cursor: pointer;
      }
      h3 {
        margin-bottom: 0;
        white-space: nowrap;
      }
      .links {
        a {
          color: @white;
          font-size: 16px;
          padding-left: 12px;
          .anticon {
            &:hover {
              svg {
                transition: all 0.5s ease;
                transform: scale(1.3);
              }
            }
          }
        }
      }
      .socials {
        @media (max-width: 382px) {
          display: none;
        }
      }

      .text-lg {
        display: block;
        @media (max-width: 550px) {
          display: none;
        }
      }
      .text-sm {
        display: none;
        @media (max-width: 575px) {
          display: block;
          margin: 0;
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      width: 300px;
      justify-content: flex-end;
    }
    .contact-details {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      .links {
        margin-left: 15px;
        .tiktok {
          fill: @white;
        }
      }

      .phone-links {
        margin-left: 20px;
      }
      .text {
        margin-left: 5px;
        font-size: 14px;
        @media (max-width: 875px) {
          display: none;
        }
      }
    }
  }
  .ant-layout-header {
    background: @white;
    transition: height 0.15s ease-out;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }
  .main-nav {
    background: @primary !important;
  }
  .text-white {
    color: @white;
  }
  .ant-menu-horizontal {
    border: 0;
  }
  .ant-menu-item::after {
    display: none;
  }
  .ant-menu {
    background: @white;
  }
}

a:hover {
  text-decoration: none;
}

.dropdown-text {
  cursor: pointer;
}

.list-group-flush {
  list-style: none;
}
.mb-2 {
  margin-bottom: 10px;
}
.language-switcher {
  color: @muted-color;
  margin-right: 10px;
  &:hover {
    color: @text-color;
    cursor: pointer;
  }
}

.header-main {
  .ant-menu {
    @media (max-width: 950px) {
      display: none;
    }
  }
}

.mobile-devices {
  display: none;
  .ant-btn {
    border-radius: 6px;
  }
  @media (max-width: 950px) {
    display: block;
  }
}

.ant-layout {
  position: relative;
}
.collapse-menu-wrapper {
  position: relative;
}
.collapse-menu {
  background: #fff;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.5s ease-in;
  text-align: center;
  list-style: none;
  z-index: -5;
  padding: 10px 0;
  li {
    padding: 7px 0;
    a {
      font-size: 16px;
      color: #637381;
      &.active {
        color: @primary;
      }
    }
  }

  &.collapse-open {
    transform: translateY(0%);
    transition: all 0.5s ease-out;
    opacity: 1;
    height: 250px;
    top: 0px;
    z-index: 99999;
    li {
      display: block;
    }
  }

  &.collapse-close {
    li {
      display: none;
      transition: all 0.5s ease-out;
    }
  }
}

.config-dropmenu {
  border-radius: 8px;
  position: relative;
  width: 200px;
}

.nav-login {
  background-color: @white;
  color: @primary;
}

.pr-2 {
  padding-right: 7px;
}

.ant-dropdown {
  z-index: 9999999;
}
.ant-dropdown-menu {
  top: 18px !important;
  z-index: 999999;
  overflow: auto;
  .ant-dropdown-menu-item {
    &:hover {
      color: @primary !important;
      background-color: @white;
    }
  }
}

.donation-btn {
  background-color: #ff7700 !important;
  color: @white;
  border-color: #ff7700 !important;
  font-size: 16px;
  img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  &:hover,
  :active,
  :focus,
  :visited,
  :link {
    background-color: #ff771f !important;
    opacity: 1.2;
    border-color: #ff7700 !important;
    color: @white;
  }
}
