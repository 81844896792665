.footer-wrapper {
  padding: 0;
  margin-top: 50px;
  .footer-contact {
    background-color: @primary;
    padding: 50px 0 10px;
    h4 {
      margin-bottom: 20px;
    }
    .links {
      min-height: 30px;
      background-color: @primary;
      .left {
        float: left;
        font-size: 15px;
        width: 50%;
        @media (max-width: 736px) {
          width: 100%;
          text-align: center;
        }
      }
      .right {
        float: right;
        font-size: 15px;
        width: auto;
        display: flex;
        justify-content: space-evenly;
        a {
          color: @white !important;
          margin-left: 15px;
          &:hover {
            color: @white !important;
            transition: all 0.3s ease-in-out;
          }
        }
        @media (max-width: 736px) {
          margin-top: 10px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .footer-social {
    background-color: @footer-bg;
    padding: 40px;
    @media (max-width: 768px) {
      .ant-row {
        display: flex;
        .ant-col:first-child {
          width: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 20px;
          .embbed {
            width: 320px !important;
          }
        }
        .ant-col {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
    }
    @media (max-width: 575px) {
      padding: 40px 20px;
      .ant-row {
        display: flex;
        .ant-col:first-child {
          width: 320px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          .embbed {
            width: 100% !important;
          }
        }
      }
    }
    h2 {
      margin-bottom: 20px;
      color: @primary;
      font-weight: 700;
    }
  }
  .embbed {
    width: 100%;
    height: 350px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .twitter {
    overflow-y: scroll;
  }
  .footer-details {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 45px;
    li {
      display: flex;
      margin-bottom: 16px;
      .icon {
        margin-right: 4px;
      }
    }
  }
  .follow-title {
    // font-size: 16px;
    font-weight: 550;
  }
}

.footer-row {
  @media (max-width: 768px) {
    display: flex;
    .footer-col:last-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 20px;
    }
    .footer-col {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .footer-contact {
      margin-bottom: 20px;
    }
  }
  .contact-title {
    text-align: left;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  .ant-col {
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
}

.footer-col {
  .donation-footer{
    max-width: 210px;
  }
  .social-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: all 0.3s ease;
      background-color: @white;
      margin-right: 16px;

      a {
        color: @primary;
        font-size: 18px;
        .tiktok {
          fill: @primary;
        }
      }
      &:hover {
        background-color: @primary;
        a {
          color: @white;
          .tiktok {
            fill: @white;
          }
        }
      }
    }
  }
  .we-accept {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    h5 {
      font-size: 15px;
      width: auto;
    }
    img {
      height: 25px;
      width: 80px;
    }
  }
  .qr-details {
    font-size: 16px;
    margin-top: 10px;
  }
}

.footer-app-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer-icon {
    margin-bottom: 20px;
    svg {
      width: 170px;
      height: 170px;
    }
  }
}
