.error-boundary-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .error-icon {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
  }
  h1 {
    text-align: center;
    height: 80px;
    font-size: 26px;
    font-weight: 600;
    color: @primary;
    margin: 0 auto;
  }
  .ant-btn {
    min-width: 200px;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    padding: 20px;
    .error-icon {
      .anticon {
        svg {
          width: 200px !important;
          height: 230px !important;
        }
      }
    }
    h1{
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
