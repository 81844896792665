.gallery-container {
  padding: 80px 0;
  position: relative;
  .galleries {
    padding-top: 40px;
    .gallery {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        display: block;
        min-height: 380px;
        max-height: 380px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        position: relative;
        border-radius: 10px;
      }
      .overlay {
        position: absolute;
        z-index: 999;
        bottom: 10px;
        left: 30px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 98.5%;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 1);
        border-radius: 10px;
      }
      .img-count {
        position: absolute;
        height: 35px;
        z-index: 999;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        text-align: center;
        font-weight: bold;
      }
    }
    .video-container {
      width: 100%;
      max-width: 100%;
      .video {
        width: 100%;
        max-width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .ant-typography {
        margin-top: 10px;
      }
    }
  }
  .gallery-info {
    color: @muted-color;
    margin-top: 20px;
    width: 300px;
    display: flex;
    justify-content: space-between;
  }
  .gallery-images {
    padding-top: 40px;
    .gallery-image {
      min-height: 380px;
      max-height: 380px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      cursor: pointer;
      border-radius: 10px;
    }
  }
}

.carousel-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  .image-carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon {
      font-size: 30px;
      color: @white;
      cursor: pointer;
    }
    .main-carousel {
      margin: 0 20px;
      max-width: 50%;
      width: auto;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      .cross {
        position: absolute;
        top: -15px;
        right: -15px;
      }
      .ant-carousel {
        width: 100%;
        .slick-slider {
          width: auto;
          height: 100%;
        }
        img {
          width: auto;
          height: 400px;
          margin: 0 auto;
          object-fit: cover;
          object-position: center;
        }
      }
      @media (max-width: 1000px) {
        max-width: 80%;
      }
    }
  }
}

.ant-pagination {
  margin-top: 20px;
  text-align: right;
}
