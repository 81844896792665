// @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;300;400;500;60;7000&display=swap');
.home-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: @bg-color;
}

// ?login

.login-page {
  display: flex;
  align-items: center;
  .ant-row {
    width: 100%;
  }
  &-form {
    padding: 30px;
    background-color: @white;
    border-radius: 5px;
    max-width: 620px;
    margin: auto;
    @media (max-width: 991px) {
      padding: 30px;
    }
    @media (max-width: 475px) {
      padding: 30px 10px;
    }
    h3 {
      text-transform: capitalize;
    }
  }
  .link {
    text-align: center;
    display: block;
    text-transform: capitalize;
  }
  .ant-btn-primary {
    width: 100%;
  }
}

// register-page
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f2f5;
  min-height: 100vh;
  &-form {
    padding: 30px 0;
    background-color: @white;
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1024px) {
      padding: 30px;
    }
    @media (max-width: 475px) {
      padding: 30px 10px;
    }
    .ant-input {
      padding-left: 10px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      padding-left: 10px !important;
    }
    h2 {
      text-align: center;
    }
    &_button {
      width: 100%;
    }
  }
  .ant-form-item:last-child {
    margin-bottom: 0 !important;
  }
  .ant-btn-primary {
    width: 100%;
  }
}

.register-btn-wrapper {
  max-width: 300px;
  margin: 0 auto;
  @media (max-width: 575px) {
    width: auto;
  }
}
.forget-password-btn {
  margin-top: 40px;
  width: 350px;
}
.link-login {
  font-size: 14px;
  text-align: center;
  width: 100px;
  margin: 0 auto;
}
.sub-title {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  // margin-top: 50px;
  justify-content: center;
  // color: @primary;
  .text {
    margin: 0 10px;
  }
}

.citizen-status {
  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.otp-text {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.muted-text {
  color: @muted-color;
}

.star {
  font-size: 8px;
  margin: 8px 2px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.home-page {
  background-color: #ececec;
  position: relative;
  .side-img {
    position: absolute;
  }
}

.ant-card {
  border-radius: 8px;
}

.banner {
  background-color: #ececec;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  .left{
    position: absolute;
    left: 0;
    top: 50%;
    width: 50px;
    height: 50px;
    z-index: 9999;
    span {
      width: 100%;
      height: 100%;
      svg{
        width: 20px;
        height: 20px;
        color: #fff;
      }
    }
  }
  .right {
    position: absolute;
    right: 0;
    top: 50%;
    width: 50px;
    height: 50px;
    z-index: 9999;
    span {
      width: 100%;
      height: 100%;
      svg{
        width: 20px;
        height: 20px;
        color: #fff;
      }
    }
  }
  .ant-carousel {
    width: 100% !important;
    height: 100%;
    max-height: 710px !important;
    .banner-img {
      width: 100%;
      height: 100%;
      min-height: 300px !important;
      max-height: 710px;
      object-fit: cover;
      object-position: center;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.icon {
  font-size: 16px;
  color: @white;
  font-weight: 800;
}

.container {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  transition: all 0.3s ease;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.align-items-center {
  align-items: center;
}

// banner
.banner-content {
  h2 {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
  }
  p {
    margin: 15px 0 25px;
    font-size: 15px;
  }
  .round {
    border-radius: 50px;
    padding: 0 34px;
    box-shadow: 2px 3px 6px rgba(57, 175, 159, 0.32);
  }
}

.ml-10 {
  margin-left: 10px;
}

//?otp page
.otp__input {
  height: 50px;
  width: 50px !important;
  font-size: 26px;
  border: 1px solid @primary;
  outline: none;
  border-radius: 5px;
}

.otp__input_container {
  justify-content: center;
  div {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
}

.inner-page-content {
  padding: 80px 0;
}

//?contact page
.contact-page {
  h2 {
    margin-bottom: 30px;
  }
  .ant-row {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .mapouter {
    margin-bottom: 50px;
  }
}
.contact-card {
  min-height: 200px;
  border: #fbe6e6 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    transform: scale(1.03);
    transition: all ease 0.5s;
  }
  @media (max-width: 575px) {
    min-height: 80px;
    .ant-card-body {
      padding: 20px;
    }
  }
  .ant-card-body {
    h3 {
      padding-top: 20px;
    }
  }
}
.contact-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.contact-icon {
  font-size: 50px;
  color: @primary;
  width: 100%;
  text-align: center;
  fill: @primary;
}

.about-text {
  font-family: 'Mukta', sans-serif !important;
  font-size: 18px;
  margin: 40px 0;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  // line-height: 5px;
}

.mapouter {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 10px auto;

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 90%;
    height: 100%;
    margin: 10px auto;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.inner-content-wrap {
  padding: 40px 0;
}

.top-nav-dropdown {
  top: 45px !important;
}

.logged-in {
  .ant-avatar-icon {
    cursor: pointer;
  }
}
.ant-input-affix-wrapper > input.ant-input {
  padding-left: 20px !important;
}

.error-notification {
  background-color: #fff2f0;
}

.success-notification {
  background-color: #f6ffed;
}

.download-container {
  background-color: #e6e7e9;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  height: 200px;
  overflow: hidden;
  position: relative;
  .ant-avatar {
    width: 100%;
    height: 100%;
    background-color: @white;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
    .anticon {
      width: 100%;
      height: 100%;
      padding: 35px 20px 20px;
      fill: @muted-color;
      svg {
        width: 70%;
        height: 70%;
      }
    }
  }
  .ant-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 30px;
    width: 100%;
    background-color: @secondary;
    // border: 1px solid #cdcdcd;
    text-align: center;
    color: @black;
  }
  &:hover,
  :active,
  :focus {
    text-decoration: none;
    transition: none;
    color: @black;
    border: 1px solid #cdcdcd;
    outline: 0 !important;
  }
}

.loading-container {
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
  .ant-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.about-cover-image {
  width: auto;
  height: 300px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  img {
    width: auto;
    height: auto;
    object-fit: cover;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: @primary;
    color: @white;
  }
  width: 600px !important;
}

.link {
  color: @primary;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.excel-sheet {
  margin-top: 50px;
  iframe {
    width: 100%;
    min-height: 600px;
    box-sizing: border-box !important;
  }
}
