.error__page__wrapper {
  .page__inside {
    width: 100vw;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 575px) {
      padding: 0px 20px;
      svg {
        width: 100%;
      }
    }
    img{
      max-width: 100vw;
      width: auto;
      margin: 20px 0 20px 80px;
      @media screen and (max-width: 490px) {
        // margin: 20px 20px;
        width: 220px;
      }
    }
    // .ant-typography {
    //   display: block;
    //   margin-top: 45px;
    //   font-size: 18px;
    //   @media screen and (max-width: 575px) {
    //     margin-top: 0px;
    //     font-size: 13px;
    //   }
    // }
    .ant-btn-primary{
      margin-top: 20px;
      padding: 0 50px !important;
    }
    button {
      font-weight: 500;
      font-size: 16px;
      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }
  }
}
