html {
  scroll-behavior: smooth;
}

.mh-100 {
  min-height: 100vh;
}

.vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  i {
    background-color: @primary;
  }
}
.d-flex {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100% !important;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

a {
  color: @primary;
  &:hover {
    color: @primary;
    text-decoration: underline;
  }
}

.align-items-center {
  align-items: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.list-none {
  list-style: none;
}

.p-0 {
  padding: 0 !important;
}

.ant-col-xs-12 {
  @media (max-width: 475px) {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -5px;
  font-size: 20px;
  color: @danger-text;
  @media (max-width: 991px) {
    margin-right: -6px;
  }
}

/*For Large screen only*/
@media (max-width: 765px) {
  .order-2 {
    order: 1;
  }
  .order-3 {
    order: 2;
  }
}
@media (max-width: 575px) {
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 1;
    margin-bottom: 20px;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.ant-row {
  max-width: 100vw !important;
}

.m-y-50{
  margin: 50px 0 !important;
}

.pointer{
  cursor: pointer !important;
}