.ant-btn-link {
  color: @primary;
  &:hover,
  &:active,
  &:focus {
    color: @primary;
    background: transparent;
    border-color: transparent;
  }
}

.ant-btn-primary {
  background-color: @primary;
  border-color: @primary;
  background-position: center;
  transition: background 0.8s;
  height: 38px;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 0 15px !important;
  &:hover,
  &:focus {
    background-color: darken(@primary, 5%);
    border-color: darken(@primary, 5%);
  }
  &:active {
    background-color: darken(@primary, 10%);
    border-color: darken(@primary, 10%);
  }
}

.bg-secondary {
  background-color: @secondary;
  color: @white;
  border: none;
  &:hover {
    background-color: @secondary;
    color: white;
  }
}

.ant-modal-footer,
.ant-upload {
  .ant-btn {
    background-position: center;
    transition: all ease 0.3s;
    height: 38px;
    text-transform: capitalize;
    border-radius: 5px;

    &:hover,
    &:active,
    &:focus {
      color: @primary;
      background: #fff;
      border-color: @primary;
    }
    &.active {
      background-size: 100%;
      transition: background 0s;
    }
  }
}

.ant-switch-checked {
  background-color: @primary;
}

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
