.executive-members-container {
  padding: 80px 0;
  .executive-members {
    margin-top: 40px;
    .executive-member {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .executive-member-image {
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 20px;
        .ant-avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .candidate-title {
        text-align: left !important;
        font-size: 16px;
        .ant-tag {
          font-size: 15px !important;
          font-weight: 500;
          padding: 5px 10px !important;
        }
        h5 {
          font-weight: 600;
          margin-bottom: 0;
        }
        .candidate-votes {
          font-weight: 500;
          color: #707070;
        }
      }
    }
  }
}
