.ant-input-affix-wrapper,
.ant-input {
  border-radius: 5px;
  overflow: hidden;
  &:focus {
    box-shadow: 0 0 0 2px rgba(@primary, 0.2);
    border-color: @primary;
  }
}

.ant-picker {
  border-radius: 5px;
  min-height: 42px;
  // padding:0px;
  overflow: hidden;
  &:focus {
    box-shadow: 0 0 0 2px rgba(@primary, 0.2);
    border-color: @primary;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  &:hover {
    border-color: @primary;
  }
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 30px;
}

.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  color: @primary;
}

.ant-input-suffix {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ant-input-prefix {
  position: absolute;
  left: 15px;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: @primary;
    border-color:  @primary;
  }
}

.ant-form-item-label {
  text-align: left;
  text-transform: capitalize;
}

.form-ant-items {
  .ant-input {
    padding-left: 15px !important;
  }
}
.ant-input {
  &:placeholder-shown {
    text-transform: capitalize;
  }
}

.ant-progress-steps-item-active {
  background: @primary;
}

.ant-progress-steps-outer {
  margin-bottom: 15px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 6px;
}

.required-label {
  label{
    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.ant-input-group-addon{
  width: 110px;

  padding: 0 !important;
  .ant-select{
    .ant-select-selector{
      height: 100%;
      .ant-select-selection-search{
        margin-right: 2px;
      }
      .ant-select-selection-item{
        margin-top: 3px;
      }
    }
  }
  .ant-input{
    border-radius: 0 5px 5px 0 !important;  
  }
}

.ant-input, .ant-select, .ant-picker{
  width: 100%;
  min-height: 40px !important;
  border-radius: 5px;
  font-size: 14px;
}

.ant-form{
  transition: all 1.5s ease !important;
}