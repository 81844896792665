.candidates-container {
  padding: 50px 0;
  .filter {
    margin: 40px 0 20px;
    position: relative;
    .buttons {
      min-width: 200px;
      display: flex;
      justify-content: end;
      .ant-btn {
        border-radius: 5px;
        height: 43px !important;
        margin-left: 10px;
      }
    }
  }
  .candidates {
    padding: 0 !important;
    .vote-casted {
      font-size: 15px;
      font-weight: 500;
      color: @danger-text;
      margin-bottom: 30px;
    }
    .address-container {
      display: flex;
      .address-info {
        margin-right: 20px;
      }
    }
    .candidate-info
      .ant-form-item
      .ant-form-item-control
      .ant-form-item-control-input
      .ant-form-item-control-input-content {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .image {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .candidate-image {
          position: absolute;
          top: 32px;
          left: 23px;
          border: 2px solid @white;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
          .name {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .position {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
      .ant-btn {
        height: 43px !important;
        border-radius: 5px;
      }
      .btn-vote {
        border-color: @success-icon;
        color: @success-icon;
      }
      .btn-winner {
        border: none;
        color: @success-icon;
        box-shadow: none;
        cursor: auto;
      }
      .btn-disabled {
        border-color: #d9d9d9 !important;
        color: @white!important;
        &:hover {
          border-color: #d9d9d9 !important;
          color: @white !important;
        }
      }
    }
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn-vote-multiple {
        width: 200px !important;
        margin-top: 20px;
        background-color: @success-icon;
        border-color: @success-icon;
      }
      .btn-vote-multiple-disabled {
        width: 200px !important;
        margin-top: 20px;
        background-color: @muted-color;
        border-color: @muted-color;
        color: @white;
      }
    }
  }
}

.candidates-modal {
  width: 510px !important;
  .candidate {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .candidate-info {
      margin: 20px 0;
      width: 100%;
      .image {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .info {
          margin-left: 20px;
          p {
            line-height: 1;
          }
          .name {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
    }
    .social-links {
      width: 100%;
      .disabled-btn {
        .anticon {
          color: #d9d9d9 !important;
          fill: #d9d9d9 !important;
        }
        &:hover {
          border-color: #d9d9d9 !important;
          color: #d9d9d9 !important;
        }
      }
      .ant-btn {
        width: 100% !important;
        height: 35px;
      }
      .btn-facebook {
        .anticon {
          color: #4267b2;
        }
        &:hover {
          color: #4267b2;
          border-color: #4267b2;
        }
      }
      .btn-twitter {
        .anticon {
          color: #1d9bf0;
        }
        &:hover {
          color: #1d9bf0;
          border-color: #1d9bf0;
        }
      }
      .btn-tiktok {
        .anticon {
          fill: #ff0050;
        }
        &:hover {
          color: #ff0050;
          border-color: #ff0050;
        }
      }
    }
    .description {
      margin-top: 50px;
    }
    .btn-vote {
      width: 50% !important;
      margin: 20px auto 0;
      background-color: @success-icon;
      border-color: @success-icon;
    }
  }
  .vote-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .buttons {
      margin-top: 20px;
      width: 50%;
      display: flex;
      justify-content: space-evenly;
      .ant-btn {
        height: 43px;
        border-radius: 5px;
        font-size: 18px;
      }
      .btn-yes {
        background-color: @success-icon;
        border-color: @success-icon;
      }
      .btn-no {
        border-color: @danger;
        color: @danger;
        .anticon {
          color: @danger;
        }
      }
    }
    .vote-success {
      margin-top: 30px !important;
      font-size: 20px;
    }
    .vote-success-sub {
      margin-top: 0px !important;
      font-size: 16px;
      color: #565859;
      font-weight: 400;
    }
    @media (max-width: 650px) {
      div {
        width: 300px !important;
        height: 200px !important;
      }
    }
    @media (max-width: 375px) {
      div {
        width: 250px !important;
        height: 150px !important;
      }
    }
  }
  @media (max-width: 520px) {
    .candidate {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .candidate-info {
        margin: 20px 0;
        width: 100%;
        .image {
          display: flex;
          flex-direction: column;
          align-items: center;
          .info {
            margin-top: 20px;
            p {
              line-height: 1;
            }
            .name {
              text-align: center;
            }
          }
        }
      }
      .description {
        margin-top: 50px;
        h5 {
          text-align: center;
        }
      }
      .btn-vote {
        width: 90% !important;
        margin: 20px auto 0;
        background-color: @success-icon;
        border-color: @success-icon;
      }
    }
  }
}

.ant-collapse {
  .ant-collapse-header {
    background-color: #f4fcff;
    color: @primary !important;
    font-size: 16px;
    font-weight: 500;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @success-icon;
    border-color: @success-icon;
  }

  .ant-checkbox-inner:hover {
    border-color: @success-icon;
  }
}

.panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-collapse-header {
  position: relative;
  .ant-collapse-header-text {
    .panel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 12px 16px 12px 35px;
    }
  }
}

.round-checkbox {
  position: relative;
  input[type='checkbox'] {
    display: none !important;
  }
}

.round-checkbox label {
  cursor: pointer;
}

.round-checkbox label input[type='checkbox'],
input[type='radio'] {
  visibility: hidden !important;
  opacity: 0;
}

.round-checkbox input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
  .swastik {
    svg {
      path,
      circle {
        stroke: @black;
      }
    }
  }
}

.round-checkbox input[type='checkbox']:checked + label::after,
input[type='radio']:checked + label::after {
  opacity: 0;
}

.disabled-checkbox label {
  cursor: not-allowed;
}

// candidate profile
.candidate-profile {
  padding: 20px;
  display: flex;
  flex-direction: column;
  .candidate-info {
    margin: 20px 0;
    width: 100%;
    .image {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .info {
        margin-left: 20px;
        p {
          line-height: 1;
        }
        .name {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .social-links {
    width: 100%;
    .disabled-btn {
      .anticon {
        color: #d9d9d9 !important;
        fill: #d9d9d9 !important;
      }
      &:hover {
        border-color: #d9d9d9 !important;
        color: #d9d9d9 !important;
      }
    }
    .ant-btn {
      width: 100% !important;
      height: 35px;
    }
    .btn-facebook {
      .anticon {
        color: #4267b2;
      }
      &:hover {
        color: #4267b2;
        border-color: #4267b2;
      }
    }
    .btn-twitter {
      .anticon {
        color: #1d9bf0;
      }
      &:hover {
        color: #1d9bf0;
        border-color: #1d9bf0;
      }
    }
    .btn-tiktok {
      .anticon {
        fill: #ff0050;
      }
      &:hover {
        color: #ff0050;
        border-color: #ff0050;
      }
    }
  }
  .description {
    margin-top: 50px;
  }
  .btn-vote {
    width: 50% !important;
    margin: 20px auto 0;
    background-color: @success-icon;
    border-color: @success-icon;
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-empty {
    .ant-empty-image {
      .ant-empty-img-simple {
        g {
          .ant-empty-img-simple-ellipse{
            fill: gray !important;
          }
          .ant-empty-img-simple-g {
            stroke: black !important;
          }
        }
      }
    }
    .ant-empty-description {
      color: black;
    }
  }
}
