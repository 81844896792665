@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.text-primary {
  color: @primary !important;
}

.text-secondary {
  color: @muted-color !important;
}

.text-black {
  color: @black !important;
}

.text-white {
  color: @white !important;
}

*, html, body{
  font-family: 'Poppins', sans-serif;
}

.mukta-font{
  font-family: 'Mukta', sans-serif !important;
}