.donation-container {
  margin-top: 40px;
  .title {
    // text-align: center;
    margin-top: 20px;
    span {
      font-size: 16px;
    }
  }
  .donation-steps {
    font-size: 15px;
    line-height: 2.5;
    ol {
      padding: 20px !important;
    }
  }
  .payment-methods {
    // display: flex;
    // justify-content: flex-start;
    .payment-method-container {
      display: inline-block;
      // margin-right: 20px;
      text-align: center;
      .selected-payment-method {
        border: 0.5px solid @primary !important;
      }
      .payment-method-image {
        background-color: @white;
        border: 0.5px solid @secondary;
        border-radius: 5px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          padding: 0px;
        }
      }
      .payment-method-name {
        z-index: 999;
        margin: 20px 0;
        display: block;
        height: 100%;
      }
      &:hover {
        .payment-method-image {
          border: 0.5px solid @primary;
          cursor: pointer;
        }
      }
    }
  }
  .payment-amount-container {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    .payment-amount {
      width: 80%;
      height: 44px;
      border-radius: 5px;
      color: @black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .amount {
    label {
      color: @white !important;
    }
  }
}

.ant-radio-group {
  margin-top: 20px;
  width: 100%;
  // display: flex;
  // justify-content: flex-start;
  font-size: 14px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
    border: none;
  }
}

.fonepay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60%;
    // width: 100%;
    padding: 0 20px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
.mt-20 {
  margin-top: 20px;
}
.bank-info-container {
  width: 100%;
  margin: 20px auto 0;
  .bank-info {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-descriptions-item-container {
      width: 400px;
      display: flex;
      justify-content: space-between;
      .ant-descriptions-item-label {
        font-size: 16px;
      }
      .ant-descriptions-item-content {
        font-size: 16px;
        // display: flex;
        // justify-content: flex-end;
      }
      @media (max-width: 400px) {
        width: 100%;
        .ant-descriptions-item-label {
          font-size: 14px;
          width: 100px;
        }
        .ant-descriptions-item-content {
          font-size: 14px;
          display: flex;
          justify-content: flex-end;
          margin-top: 0 !important;
        }
      }
    }
  }
}

.ant-card {
  max-width: 80%;
  margin: 0 auto;
  .donation-success-container {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 665px) {
      justify-content: center;
      text-align: center;
    }
    .donation-success-content {
      // width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .donation-success-content-header {
        font-size: 20px;
      }
      .ant-divider {
        margin: 5px 0 24px;
        color: @muted-color;
      }
      .donation-success-content-body {
        font-size: 16px;
        line-height: 2.5;
        color: @muted-color;
        .ant-btn {
          margin-top: 20px;
          color: @primary;
          border: 1px solid @primary;
          border-radius: 5px;
          padding: 7px 15px;
        }
      }
    }
    .donation-success-img {
      @media (max-width: 875px) {
        .anticon {
          svg {
            width: 200px;
            height: 200px;
          }
        }
      }
      @media (max-width: 665px) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.loading {
  width: 100%;
  height: 500px;
  .ant-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
