.blog-container {
  padding: 80px 0;
  .active-category {
    color: @primary !important;
    border: 1px solid @primary !important;
  }
  .blog-category-container {
    text-align: center;
    color: @muted-color;
    border: 1px solid @muted-color;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: @primary;
      border: 1px solid @primary;
    }
  }
  .blog-content-container {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .blog-content-image {
      width: 35%;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .blog-content-details {
      width: 60%;
      .blog-content-title {
        font-size: 17px;
        font-weight: 600;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          overflow: hidden;
        }
      }
    }
    &:hover {
      .blog-content-image {
        opacity: 1.3;
      }
      .blog-content-details {
        .blog-content-title {
          color: @primary !important;
        }
      }
    }
  }
  .blog-content-container-big {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .blog-content-image {
      width: 48%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-position: center;
      }
    }
    .blog-content-details {
      width: 47%;
      .blog-content-title {
        h3 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          overflow: hidden;
        }
      }
      .blog-content-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
      }
      .date {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: @muted-color;
        position: absolute;
        bottom: 0;
      }
    }
    &:hover {
      .blog-content-image {
        opacity: 1.3;
      }
      .blog-content-details {
        .blog-content-title {
          .ant-typography {
            color: @primary;
          }
        }
      }
    }
  }
  .blog-cover-image {
    width: auto;
    height: 300px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
}
.gallery {
  .gallery-images {
    padding-top: 10px;
    .gallery-image {
      min-height: 380px;
      max-height: 380px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      cursor: pointer;
      border-radius: 10px;
    }
  }
}
