.profile_wrapper {
  padding: 50px 0;
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 173.5%;

    @media (max-width: 576px) {
      text-align: center;
    }
  }
  .profile_content {
    // overflow: hidden;
    @media (max-width: 865px) {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .progress_bar {
    width: 100%;
    margin: 40px auto;

    @media (max-width: 865px) {
      width: 50px;
      margin: 10px auto;
    }
  }
  .user_info {
    margin-top: 10px;
    .profile_pic_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 576px) {
        text-align: center;
      }
    }
  }

  .profile-pic {
    @media (max-width: 576px) {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar-container {
      position: relative;
      cursor: pointer;
      max-width: 150px;
      &:hover .overlay {
        opacity: 0.8;
        display: block;
      }
    }

    .avatar {
      display: block;
      width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: 0.5s ease;
      background-color: @black;
    }

    .text {
      color: @white;
      opacity: 1;
      font-size: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .profile-desc {
    .verify-btn {
      .ant-btn {
        height: 30px;
        width: 160px;
      }
    }
    @media (max-width: 576px) {
      max-width: 80%;
      margin: 10px auto;
      .verify-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  .info {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .edit-profile {
    margin-top: 30px;
    .informations {
      margin-top: 10px;
      &-header {
        padding: 10px 16px;
        background-color: @header-primary;
        border-radius: 4px;
        h4 {
          color: @primary;
          font-size: 1rem;
          font-weight: 500;
        }
        .ant-btn {
          svg {
            font-size: 16px;
            color: @icon-color;
          }
        }
      }
      .informations-table {
        margin-top: 20px;
        padding: 0px 16px;
      }
      .ant-descriptions-middle .ant-descriptions-row > td {
        padding-bottom: 16px;
        font-size: 14px;
      }
    }
  }
}

.no-border {
  padding: 0;
  height: auto;
  width: auto;
  border: 0;
  background-color: transparent;
}

.not-filled {
  width: 50px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  background: #fff4f4;
  border-radius: 27px;
  color: @danger;
  font-size: 14px;
}

.ant-descriptions-item-label {
  color: @black;
}

.rsp-modal {
  .ant-modal-body {
    padding: 40px;
    .edit-form-wrapper {
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .register-btn-wrapper {
        margin-top: 30px;
        max-width: 300px;
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
    .ant-input-affix-wrapper > input.ant-input {
      padding-left: 0 !important;
    }
  }
}

.rsp-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-modal-body {
    padding: 20px;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 576px) {
    width: 80% !important;
  }
}

.rsp-book-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  .ant-modal-content {
    width: 100%;
    .ant-modal-body {
      padding: 20px;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 576px) {
    width: 80% !important;
  }
}

.ant-upload.ant-upload-drag {
  border: 1px solid @border-color;
  border-radius: 6px;
}

.min-h-800 {
  padding: 20px;
  @media (max-width: 991px) {
    max-height: 60vh;
    overflow: auto;
  }
}

.label-width {
  .ant-descriptions-item-label {
    width: 180px;
  }
}
.ant-descriptions-item-container {
  flex-wrap: wrap;
}

.avatar-modal {
  .ant-upload-list-item-info {
    width: 86px;
    height: 86px;
    .ant-upload-span {
      width: 100%;
      height: 100%;
      a {
        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

.candidate-container {
  padding: 10px 0 !important;
  .candidate-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    .ant-btn {
      height: 40px;
      padding: 0 20px !important;
    }
  }
}
