.dashboard-bg {
  background-color: #f3fbff;
  padding: 20px 0;
  margin-top: 100px;
}

.dashboard {
  margin-top: 100px;
}
.maintenance-content {
  h1 {
    font-size: 44px;
    padding: 0 20px;
  }
  h5 {
    font-size: 24px;
    padding: 0 20px;
  }
  @media (max-width: 575px) {
    h1 {
      font-size: 30px;
    }
    h5 {
      font-size: 20px;
    }
  }
}
.dashboard-content {
  h1 {
    padding: 0 20px;
  }
  @media (max-width: 775px) {
    h1 {
      font-size: 30px;
    }
    .buttons-container {
      .bottom {
        .ant-btn {
          margin-top: 0 !important;
        }
      }
    }
  }
  @media (max-width: 575px) {
    h1 {
      font-size: 25px;
    }
  }
}

.home-container {
  min-height: 100vh;
  .home-title {
    margin-top: 50px;
    width: 450px;
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .qr-container {
    width: 550px;
    height: 410px;
    border-radius: 16px;
    position: absolute;
    top: -250px;
    right: 0;
    z-index: 999;
    background-color: @white;
    .image-container {
      background: url(/assets/images/banner.jpg) no-repeat center center;
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 16px;
      .color-container {
        padding: 20px;
        background-color: rgba(1, 147, 222, 0.9);
        height: 100%;
        width: 100%;
        border-radius: 16px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .counter-title {
          h4 {
            font-size: 23px;
            font-weight: 500;
            margin: 0 !important;
          }
          .time {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .counter {
              // max-width: 40px !important;
              h4 {
                font-size: 24px;
                font-weight: 600;
                margin: 10px 0 !important;
              }
            }
          }
        }
        .buttons-container {
          .ant-btn {
            width: 100%;
            height: 50px;
            font-size: 15px;
            border-radius: 5px;
          }
          .default-btn {
            color: @primary;
          }
          .transparent-btn {
            background-color: transparent;
            border: 1px solid @white;
            color: @white;
          }
          .donate-btn {
            background-color: #ff7700 !important;
            border-color: #ff7700 !important;
            color: @white !important;
          }
        }
        @media (max-width: 575px) {
          .counter-title {
            h3 {
              font-size: 20px;
              font-weight: 500;
            }
          }
          .time {
            .counter {
              h4 {
                font-size: 17px !important;
                font-weight: 500 !important;
              }
            }
          }
          .buttons-container {
            margin-top: 0;
            .ant-btn {
              width: 250px;
            }
          }
        }
      }
    }
  }
  .registration-container {
    min-height: 200px;
    padding: 50px;
    margin-top: 100px;
    background: linear-gradient(92.26deg, #12131a 1.38%, #224464 100%);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    .registration-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: @white !important;
      h4 {
        margin-bottom: 10px !important;
      }
      h2 {
        margin-top: 0px !important;
        margin-bottom: 20px;
      }
      .ant-btn {
        width: 180px;
        height: 50px;
        border-radius: 25px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .registration-img {
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 630px) {
      min-height: 150px;
      padding: 20px;
      .registration-title {
        h2 {
          font-size: 20px;
        }
        h4 {
          font-size: 14px;
        }
        .ant-btn {
          height: 40px;
          font-size: 16px;
        }
      }
      .registration-img {
        width: 100px;
        height: 100px;
      }
    }
    @media (max-width: 630px) {
      .registration-title {
        h2 {
          font-size: 18px;
        }
        h4 {
          font-size: 13px;
        }
        .ant-btn {
          width: 150px;
          height: 35px;
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .home-title {
      width: 100%;
      text-align: center;
      margin-top: 150px !important;
    }
    .qr-container {
      width: 550px;
      right: 50%;
      top: -450px;
      transform: translate(50%, 0);
    }
  }
  @media (max-width: 775px) {
    .qr-container {
      top: -450px;
      width: 500px;
    }
  }
  @media (max-width: 575px) {
    .qr-container {
      width: 300px;
    }
  }
  @media (max-width: 450px) {
    .qr-container {
      top: -380px;
      width: 300px;
      height: 370px;
      .counter-title {
        h3 {
          font-size: 21px;
        }
      }
      .dashboard-content {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
}

.countdown-container {
  margin-top: 80px;
  background: url(/assets/images/banner.jpg) no-repeat center center;
  height: 236px;
  position: relative;
  .color-container {
    background-color: rgba(1, 147, 222, 0.9);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .countdown {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .counter-container {
        .counter-title {
          h4 {
            font-size: 23px;
            font-weight: 500;
            margin: 0 !important;
          }
          .time {
            width: 90%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .counter {
              h4 {
                font-size: 24px;
                font-weight: 600;
                margin: 10px 0 !important;
              }
            }
          }
        }
      }
      .buttons-container {
        display: flex;
        flex-direction: column;
        // min-width: 400px;
        // max-width: 400px;
        width: 400px;
        .top {
          width: 100%;
          .ant-btn {
            width: 100%;
            height: 50px;
            font-size: 15px;
            border-radius: 5px;
          }
          .default-btn {
            color: @primary;
          }
          .transparent-btn {
            background-color: transparent;
            border: 1px solid @white;
            color: @white;
          }
        }
        .bottom {
          width: 100%;
          .ant-btn {
            width: 100%;
            height: 50px;
            font-size: 15px;
            border-radius: 5px;
            .anticon {
              // margin-top: 5px;
              svg {
                stroke: @white;
              }
            }
          }
        }
      }
    }
  }
}

.events-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 100px;
  .events-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .more-text {
        font-size: 14px;
        font-weight: 400;
        color: @muted-color;
        margin-right: 10px;
      }
      .anticon {
        color: @primary;
        align-items: center;
        width: 20px !important;
        height: 15px !important;
      }
      &:hover {
        .more-text {
          color: @primary !important;
        }
      }
    }
  }
  .event-container {
    width: 100%;
    .event {
      .event-image {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        @media (max-width: 576px) {
          height: 300px;
        }
        @media (max-width: 400px) {
          height: 200px;
        }
      }
      .event-title {
        h5 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:hover {
        cursor: pointer;
        .event-title {
          .ant-typography {
            color: @primary;
          }
        }
      }
    }
  }
  .candidate-container {
    display: flex;
    justify-content: space-between;
    .candidate {
      width: 300px;
      .candidate-image {
        width: 300px;
        height: 300px;
      }
    }
  }
  .candidate-container,
  .candidate-multiple-container {
    position: relative;
    width: 100%;
    .candidate {
      .candidate-image {
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .candidate-title {
        font-size: 16px;
        h5 {
          font-weight: 600;
        }
        .candidate-votes {
          font-weight: 500;
          color: #707070;
        }
      }
      &:hover {
        cursor: pointer;
        .candidate-title {
          .ant-typography {
            color: @primary;
          }
        }
      }
    }
    .candidate-multiple {
      .candidate-image {
        height: 250px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .candidate-title {
        font-size: 16px;
        .ant-tag{
          font-size: 15px !important;
          font-weight: 500;
          padding: 5px 10px !important;
        }
        h5 {
          font-weight: 600;
          margin-bottom: 0;
        }
        .candidate-votes {
          font-weight: 500;
          color: #707070;
        }
      }
      &:hover {
        cursor: pointer;
        .candidate-title {
          .ant-typography {
            color: @primary;
          }
        }
      }
    }
    .versus {
      width: 100px;
      height: 75px;
      background-color: #d8f1fa;
      font-size: 28px;
      font-weight: 700;
      color: @primary;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media (max-width: 675px) {
    .candidate-container {
      .candidate {
        width: 200px;
        .candidate-image {
          width: 200px;
          height: 200px;
        }
      }
      .versus {
        width: 75px;
        height: 50px;
      }
    }
  }
  @media (max-width: 460px) {
    .candidate-container {
      .candidate {
        width: 150px;
        .candidate-image {
          width: 150px;
          height: 150px;
        }
      }
      .versus {
        width: 75px;
        height: 50px;
      }
    }
    .candidate-multiple {
      .candidate-image {
        height: 300px !important;
      }
    }
  }
  @media (max-width: 345px) {
    .candidate-container {
      .candidate {
        width: 120px;
        .candidate-image {
          width: 120px;
          height: 120px;
        }
      }
      .versus {
        width: 65px;
        height: 40px;
      }
    }
    .candidate-multiple {
      .candidate-image {
        height: 250px !important;
      }
    }
  }
}
.blogs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .blog-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .more-text {
        font-size: 14px;
        font-weight: 400;
        color: @muted-color;
        margin-right: 10px;
      }
      .anticon {
        color: @primary;
        align-items: center;
        width: 20px !important;
        height: 15px !important;
      }
      &:hover {
        .more-text {
          color: @primary !important;
        }
      }
    }
  }
  .blogs {
    width: 100%;
    .blog {
      .blog-image {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        border-radius: 5px;
        img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .blog-title {
        h5 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 3em;
        }
        .date {
          font-size: 12px !important;
          color: @muted-color;
        }
      }
      &:hover {
        cursor: pointer;
        .blog-title {
          .ant-typography {
            color: @primary;
          }
        }
      }
    }
  }
}

.blogs-container-lower {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 100px;
  .blogs-lower {
    .blog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        .more-text {
          font-size: 14px;
          font-weight: 400;
          color: @muted-color;
          margin-right: 10px;
        }
        .anticon {
          color: @primary;
          align-items: center;
          width: 20px !important;
          height: 15px !important;
        }
        &:hover {
          .more-text {
            color: @primary !important;
          }
        }
      }
    }
    .blogs {
      width: 100%;
      .blog {
        display: flex;
        justify-content: space-between;
        .blog-image {
          width: 110px;
          height: 110px;
          margin-bottom: 20px;
          border-radius: 5px;
          img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .blog-title {
          width: 50%;
          position: relative;
          h5 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .date {
            font-size: 12px !important;
            color: @muted-color;
            position: absolute;
            bottom: 20px;
          }
        }
        &:hover {
          cursor: pointer;
          .blog-title {
            .ant-typography {
              color: @primary;
            }
          }
        }
      }
    }
  }
  .notice-lower {
    .notice {
      width: 100%;
      .notice-header {
        margin-bottom: 20px !important;
      }
      .notice-image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

.donation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 !important;
  .donation-text {
    h1 {
      margin-top: 10px !important;
    }
  }
  .donate-btn {
    height: 56px !important;
    font-size: 18px !important;
    padding: 10px 34px !important;
    background-color: #ff7700 !important;
    border-color: #ff7700 !important;
    color: @white !important;
    .anticon {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .events-container {
    margin-top: 50px !important;
  }
  .blogs-container {
    margin-top: 50px !important;
  }
  .blogs-container-lower {
    margin-top: 50px !important;
  }
  .donation {
    margin-top: 50px !important;
  }
  .dashboard-bg {
    margin-top: 50px !important;
    padding: 10px !important;
  }
  .dashboard {
    margin-top: 50px !important;
  }
  .registration-container {
    margin-top: 50px !important;
  }
}

.countdown-modal {
  .ant-modal-body {
    padding: 0 !important;
    border-radius: 16px;
  }
}
.image-container {
  background: url(/assets/images/banner.jpg) no-repeat center center;
  width: 100%;
  height: 755px;
  position: relative;
  .color-container {
    background-color: rgba(1, 147, 222, 0.9);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .counter-container {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
      .counter {
        width: 100% !important;
        h4 {
          font-size: 24px;
          font-weight: 600;
          margin: 10px 0 !important;
        }
      }
    }
    .modal-title {
      width: 60%;
      margin-top: 30px;
      h1 {
        font-size: 42px;
        font-weight: 700;
        margin: 0 !important;
      }
    }
    .buttons-container {
      margin-top: 30px;
      padding: 0 20px;
      width: 100%;
      .ant-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        border-radius: 5px;
      }
      .default-btn {
        color: @primary;
      }
      .transparent-btn {
        background-color: transparent;
        border: 1px solid @white;
        color: @white;
      }
    }
    .modal-footer {
      margin-top: 50px;
      .ant-btn {
        color: @white;
        font-size: 16px;
      }
    }
  }
  @media (max-width: 768px) {
    .color-container {
      padding: 20px;
      .counter-container {
        .counter {
          h4 {
            font-size: 20px;
          }
        }
      }
      .modal-title {
        width: 100%;
        h1 {
          font-size: 30px;
        }
      }
      .buttons-container {
        padding: 0;
      }
    }
  }
  @media (max-width: 575px) {
    .color-container {
      .counter-container {
        padding: 0;
        .counter {
          h4 {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      .modal-title {
        width: 100%;
        h1 {
          font-size: 24px;
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-close {
    top: -18px;
    right: -18px;
    .ant-modal-close-x {
      width: 38px;
      height: 38px;
      background: @square-bg;
      border: 1px solid @black;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transition: all ease 0.5s;
        background-color: #e6e6e6;
      }
      svg {
        color: @black;
      }
    }
  }
}

.colon {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 100px;
    font-weight: 900;
  }
}

.flip-book {
  width: 100%;
  margin: 0 auto;
  padding: 10px;

  .book {
    width: 100%;
    margin: 0 auto;
  }
}

.ant-tag{
  border-radius: 5px;
}