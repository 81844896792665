.main {
  print-color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @page {
    margin: 0;
    size: A4;
    -webkit-print-color-adjust: exact !important;
  }
  .ant-btn {
    position: absolute;
    top: 20px;
    right: 200px;
  }
  @media (max-width: 1100px) {
    .ant-btn {
      right: 10px;
    }
    @media print {
      .ant-btn {
        display: none !important;
      }
    }
  }
  @media (max-width: 710px) {
    flex-direction: column;
    .ant-btn {
      position: relative;
      display: block;
      margin-top: -10px;
      margin-bottom: 50px;
    }
    @media print {
      .ant-btn {
        display: none !important;
      }
    }
  }
  .profile-container {
    width: 450px;
    height: 715px;
    border: 1px solid @black;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    .profile-header {
      width: 100%;
      height: 120px;
      background-color: @primary;
      .header-img {
        width: 450px;
        // height: 100px;
        margin: 0 auto;
        display: flex;
        img {
          width: 80px;
          height: 80px;
        }
        .header-info {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          img {
            width: 350px;
            height: 66px;
          }
          .info-text {
            // font-size: 20px;
            margin-left: 20px;
            font-weight: 700;
            color: @white;
          }
        }
      }
    }
    .profile-body {
      background-color: @white;
      width: 100%;
      .body-banner {
        width: 100%;
        height: 120px;
        background-color: @primary;
        display: flex;
        justify-content: space-between;
        color: @white;
        line-height: 20px;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        padding-top: 10px;
        .left {
          margin-left: 20px;
          p {
            margin-bottom: 0;
          }
        }
        .right {
          margin-right: 20px;
          p {
            margin-bottom: 0;
          }
        }
        .profile-img {
          position: absolute;
          top: 10px;
          left: 150px;
          width: 150px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .body-info {
        width: 100%;
        height: 315px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        h5.ant-typography {
          margin: 2px 0 !important;
        }
        canvas {
          margin-top: 5px;
          width: 80px !important;
          height: 80px !important;
        }
        .signature-img {
          width: 30px;
          height: auto;
        }
        .signature {
          p {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 4px;
          }
          p:last-child {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .ant-divider-horizontal {
          color: @black !important;
          width: 50% !important;
          min-width: 50% !important;
          margin: 0 0 4px !important;
          border-top: 1px solid @black;
        }
      }
    }
    .profile-footer {
      width: 100%;
      height: 82px;
      color: @white;
      background-color: @primary;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
  }
}

@media print {
  .ant-btn {
    display: none !important;
  }
}
